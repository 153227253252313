export const ROUTES = {
  // auth
  AUTH: "auth/auth",
  LOGIN: "auth/login",
  LOGOUT: "auth/logout",

  // 2FA
  TWOFA_SETUP_CODE: "/auth/2fa/setup",
  TWOFA_SETUP_CODE_CONFIRM: "/auth/2fa/setup",
  TWOFA_LOGIN: "/auth/2fa/login",

  // admin users
  USERS: "admin/users",
  BAN_USER: (uuid: string) => `admin/users/${uuid}/ban`,
  DELETE_USER: (uuid: string) => `admin/users/${uuid}`,

  // admin vereine
  VEREINE: "admin/verein",
  VEREIN_DETAIL: (id: number) => `admin/verein/${id}`,
  VEREIN_DELETE: (id: number) => `admin/verein/${id}`,
  VEREIN_STATUS: (id: number) => `admin/verein/${id}/status`,
  VEREIN_CUR_UPLOAD: "admin/verein/my/import/programs",
  VEREIN_CUR_DOWNLOAD: "admin/verein/my/export/programs",
  VEREIN_ID_UPLOAD: (id: number) => `admin/verein/${id}/import/programs`,
  VEREIN_ID_DOWNLOAD: (id: number) => `admin/verein/${id}/export/programs`,

  FACILITIES_DL: "admin/export/facilities",
  FACILITIES_UL: "admin/import/facilities",

  // admin news
  CREATE_NEWS: "news/create_news",
  NEWSFEED: "news/feed",
  NEWS_DELETE: (id: number) => `news/${id}`,
  NEWS_EDIT: (id: number) => `news/${id}`,
  BANNER_GET: "news/banner",
  BANNER_CREATE: "news/banner",
  BANNER_PATCH: "news/banner",
};
