
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  CreateNewsRequest,
  EditNewsRequest,
  NewsResponse,
} from "@/store/news/types";
import { blobToBase64 } from "@/utility/FileUtil";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { ROUTES } from "@/constants/routes";

const newsModule = namespace("news");
const loadingModule = namespace("loading");

@Component({
  name: "CreateOrEditNews",
  components: { LoadingIndicator },
})
export default class CreateOrEditNews extends Vue {
  @Prop({ type: Object, required: false, default: undefined }) newsToEdit!:
    | NewsResponse
    | undefined;

  @newsModule.Action("createNews") createNews!: any;
  @newsModule.Action("editNews") editNews!: any;
  @loadingModule.Getter("isLoading") isLoading: any;

  routes = ROUTES;

  title = "";
  text = "";
  imageFile = null;
  imageBase64: string | null = null;
  editImageUrl: string | null = null;
  hasImageChanged = false;

  mounted() {
    if (this.newsToEdit) {
      this.title = this.newsToEdit.title;
      this.text = this.newsToEdit.text;
    }
  }

  onButtonClicked() {
    if (this.newsToEdit) {
      const request: EditNewsRequest = {
        id: this.newsToEdit.id,
        title: this.title,
        text: this.text,
        base64_image: this.imageBase64,
        image_changed: this.hasImageChanged,
      };
      this.editNews(request)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((val: NewsResponse) => {
          this.$router.back();
        })
        .catch((reason: any) => {
          console.log(reason);
        });
    } else {
      const request: CreateNewsRequest = {
        title: this.title,
        text: this.text,
        base64_image: this.imageBase64,
      };
      this.createNews(request)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((val: NewsResponse) => {
          this.$router.back();
        })
        .catch((reason: any) => {
          console.log(reason);
        });
    }
  }

  @Watch("imageFile")
  onFileAttached(newVal: File | undefined) {
    if (newVal) {
      blobToBase64(newVal).then((base64: any) => {
        this.imageBase64 = base64;
      });
      this.hasImageChanged = true;
    } else {
      this.imageBase64 = null;
    }
  }
}
