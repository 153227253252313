
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { ROUTES } from "@/constants/routes";

const authModule = namespace("auth");
const loadingModule = namespace("loading");

@Component({
  name: "TwoFactorSetup",
  components: { LoadingIndicator },
})
export default class TwoFactorSetup extends Vue {
  @authModule.Action("twoFaSetupCode") getTwoFactorCode!: any;
  @authModule.State("twoFaCode") twoFaCode!: any;
  @authModule.State("twoFaQrCode") twoFaQrCode!: any;
  @loadingModule.Getter("isLoading") isLoading: any;

  routes = ROUTES;
  enteredCode = "";

  mounted() {
    this.getTwoFactorCode();
  }

  onCodeEnteredClicked() {
    this.$emit("confirm-code-clicked", this.enteredCode);
  }
}
