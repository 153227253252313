import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const defaultServerDateFormat = "YYYY-MM-DDTHH:mm";
export const defaultDisplayFormat = "DD.MM.YYYY, HH:mm";
dayjs.extend(utc);

export function formatReadableDate(dateString: string | null): string {
  return dayjs
    .utc(dateString, defaultServerDateFormat)
    .local()
    .format(defaultDisplayFormat);
}
