import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import api from "@/api/base";
import { AxiosResponse } from "axios";
import { ROUTES } from "@/constants/routes";
import {
  CreateBannerRequest,
  CreateNewsRequest,
  EditNewsRequest,
  NewsDeletionRequest,
  NewsFeedResponse,
  NewsResponse,
  NewsState, PatchBannerRequest,
} from "./types";
import { RootState } from "../types";

const getDefaultState = () => {
  return {
    news: [],
  };
};

const state = getDefaultState();

const actions: ActionTree<NewsState, RootState> = {
  fetchNewsFeed({ commit }) {
    api
      .get(ROUTES.NEWSFEED)
      .then((response: AxiosResponse) => commit("setFeed", response.data))
      .catch((reason) => console.error(reason));
  },
  createNews({ commit }, data: CreateNewsRequest) {
    api
      .post(ROUTES.CREATE_NEWS, data)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response: AxiosResponse) =>
        commit("addCreatedNews", response.data)
      )
      .catch((reason) => console.error(reason));
  },
  editNews({ commit }, data: EditNewsRequest) {
    api
      .put(ROUTES.NEWS_EDIT(data.id), data)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response: AxiosResponse) => commit("editedNews", response.data))
      .catch((reason) => console.error(reason));
  },
  deleteNews({ commit }, request: NewsDeletionRequest) {
    api
      .delete(ROUTES.NEWS_DELETE(request.id))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response: AxiosResponse) => commit("deletedNews", request.id))
      .catch((reason) => console.error(reason));
  },
  fetchBanner({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .get(ROUTES.BANNER_GET)
        .then((response: AxiosResponse) => resolve(response.data))
        .catch((reason) => reject(reason));
    })
  },
  editBanner({ commit }, data: PatchBannerRequest) {
    return new Promise((resolve, reject) => {
      api
        .patch(ROUTES.BANNER_PATCH, data)
        .then((response: AxiosResponse) => resolve(response.data))
        .catch((reason) => reject(reason));
    })
  },
  createBanner({ commit }, data: CreateBannerRequest) {
    return new Promise((resolve, reject) => {
      api
        .post(ROUTES.BANNER_CREATE, data)
        .then((response: AxiosResponse) => resolve(response.data))
        .catch((reason) => reject(reason));
    })
  },
};

// mutations
const mutations: MutationTree<NewsState> = {
  setFeed(state, response: NewsFeedResponse) {
    state.news = response.news;
  },
  addCreatedNews(state, response: NewsResponse) {
    const current = state.news;
    current.unshift(response);
    state.news = current;
  },
  editedNews(state, response: NewsResponse) {
    const current = state.news;
    const index = current.findIndex(
      (val: NewsResponse) => val.id === response.id
    );
    if (index !== -1) {
      current[index] = response;
    }
    state.news = current;
  },
  deletedNews(state, id: number) {
    let current = state.news;
    current = current.filter((val) => {
      return val.id !== id;
    });
    state.news = current;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<NewsState, RootState> = {
  getNewsById:
    (state) =>
    (id: number): NewsResponse | undefined => {
      return state.news.find(
        (news: NewsResponse) => news.id.toString() === id.toString()
      );
    },
};

export const news: Module<NewsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
