
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  BanUserRequest,
  DeleteUserRequest,
  UserResponse,
  UserStatus,
  userStatusToText,
} from "@/store/users/types";
import { namespace } from "vuex-class";
import { DataTableHeader } from "vuetify";
import { AccountType, accountTypeToText } from "@/store/auth/types";

const usersModule = namespace("users");

@Component({
  name: "UserList",
  components: {},
})
export default class UserList extends Vue {
  @Prop(Array) users!: Array<UserResponse>;
  @usersModule.Action("banUser") banUser!: any;
  @usersModule.Action("deleteUser") deleteUser!: any;

  userAccountTypeToText(type: AccountType) {
    return accountTypeToText(type);
  }

  statusToText(status: UserStatus) {
    return userStatusToText(status);
  }

  banThisUser(uuidToBan: string) {
    const banRequest: BanUserRequest = {
      uuid: uuidToBan,
    };
    this.banUser(banRequest);
  }

  deleteThisUser(uuidToDelete: string) {
    const deleteRequest: DeleteUserRequest = {
      uuid: uuidToDelete,
    };
    this.deleteUser(deleteRequest);
  }

  get headers(): DataTableHeader[] {
    return [
      { text: "", align: "start", sortable: false, value: "image_url" },
      {
        text: "E-Mail",
        value: "email",
        sortable: true,
      },
      {
        text: "Vorname",
        value: "first_name",

        sortable: false,
      },
      {
        text: "Nachname",
        value: "last_name",
        sortable: false,
      },
      {
        text: "Account-Typ",
        value: "account_type",
        sortable: true,
      },
      {
        text: "Status",
        value: "status",
        sortable: true,
      },
      {
        text: "Aktionen",
        value: "",
        sortable: false,
      },
    ];
  }
}
