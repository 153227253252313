import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import api, { isTwoFAActive } from "@/api/base";
import { AxiosResponse } from "axios";
import { ROUTES } from "@/constants/routes";
import {
  AccountType,
  AuthResponse,
  AuthState,
  TwoFALoginRequest,
  TwoFASetupDoneRequest,
} from "./types";
import { RootState } from "../types";

const getDefaultState = () => {
  return {
    token: localStorage.getItem("auth_token") ?? null,
    isAdmin: false,
    user: null,
    isTwoFa: localStorage.getItem("is_2fa") === "true" ?? false,
    isTwoFaReady: localStorage.getItem("is_2fa_ready") === "true" ?? false,
    twoFaCode: null,
    twoFaQrCode: null,
  };
};

const state = getDefaultState();

const actions: ActionTree<AuthState, RootState> = {
  login({ commit }, data: { name: string; password: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .post(ROUTES.LOGIN, data)
        .then((response: AxiosResponse) => {
          commit("setAuthToken", response.data);
          resolve(response.data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  auth({ commit }): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .get(ROUTES.AUTH)
        .then((response: AxiosResponse) => {
          commit("setAuthToken", response.data);
          resolve(response.data);
        })
        .catch((reason) => {
          commit("logoutComplete");
          reject(reason);
        });
    });
  },
  logout({ commit }): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .post(ROUTES.LOGOUT, { fcmToken: "" })
        .then((response: AxiosResponse) => {
          commit("logoutComplete");
          resolve(response);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  twoFaSetupCode({ commit }): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .get(ROUTES.TWOFA_SETUP_CODE)
        .then((response: AxiosResponse) => {
          const splitData = response.data.split(";;;");
          const cleanedData = splitData[1].replace(
            '<?xml version="1.0" encoding="UTF-8"?>',
            ""
          );
          commit("setTwoFASetupCode", splitData[0]);
          commit("setTwoFASetupQrCode", cleanedData);
          resolve(cleanedData);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  confirmTwoFaSetup({ commit }, request: TwoFASetupDoneRequest): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .post(ROUTES.TWOFA_SETUP_CODE_CONFIRM, request)
        .then((response: AxiosResponse) => {
          commit("setTwoFASetupCode", null);
          commit("setTwoFASetupQrCode", null);
          commit("setAuthToken", response.data);
          resolve(response.data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  loginTwoFa({ commit }, request: TwoFALoginRequest): Promise<any> {
    return new Promise((resolve, reject) => {
      api
        .post(ROUTES.TWOFA_LOGIN, request)
        .then((response: AxiosResponse) => {
          commit("setAuthToken", response.data);
          resolve(response.data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
};

// mutations
const mutations: MutationTree<AuthState> = {
  setAuthToken(state, response: AuthResponse) {
    localStorage.setItem("auth_token", response.token);
    localStorage.setItem("is_2fa", String(response.is_2fa));
    localStorage.setItem("is_2fa_ready", String(response.is_2fa_ready));
    state.token = response.token;
    state.isTwoFa = response.is_2fa;
    state.isTwoFaReady = response.is_2fa_ready;
    state.isAdmin = response.user.account_type === AccountType.ADMIN;
    state.user = response.user;
  },
  logoutComplete(state) {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("is_2fa");
    localStorage.removeItem("is_2fa_ready");
    state.token = null;
  },
  setTwoFASetupCode(state, response: string) {
    state.twoFaCode = response;
  },
  setTwoFASetupQrCode(state, response: string) {
    state.twoFaQrCode = response;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<AuthState, RootState> = {
  isLoggedIn: (state) => (): boolean => {
    return (
      state.token !== null &&
      state.token !== "" &&
      ((state.isTwoFa && state.isTwoFaReady) || !isTwoFAActive())
    );
  },
  isVereinsUser: (state) => (): boolean => {
    return !state.isAdmin;
  },
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
