
import Vue from "vue";
import { Component } from "vue-property-decorator";
import ClubList from "@/components/ClubList.vue";
import { namespace } from "vuex-class";
import { VereinResponse } from "@/store/vereine/types";
import { ROUTES } from "@/constants/routes";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

const vereineModule = namespace("vereine");
const loadingModule = namespace("loading");

@Component({
  name: "ClubListView",
  components: { LoadingIndicator, ClubList },
})
export default class ClubListView extends Vue {
  @vereineModule.Action("fetchVereine") fetchVereine!: any;
  @vereineModule.State("vereine") vereine!: Array<VereinResponse>;
  @vereineModule.Getter("onlyOpenVereine") onlyOpenVereine!: any;
  @vereineModule.Getter("onlyWorkedOnVereine") onlyWorkedOnVereine!: any;
  @loadingModule.Getter("isLoading") isLoading: any;

  routes = ROUTES;

  public showWorkedOn = 0;

  mounted() {
    this.fetchVereine();
  }
}
