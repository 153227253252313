import Vue from "vue";
import Vuex from "vuex";
import { auth } from "@/store/auth";
import { vereine } from "@/store/vereine";
import { news } from "@/store/news";
import { users } from "@/store/users";
import { loading } from "@/store/loading";
import { error } from "@/store/error";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: auth,
    vereine: vereine,
    loading: loading,
    error: error,
    news: news,
    users: users,
  },
});
