
import Vue from "vue";
import HelloWorld from "@/components/HelloWorld.vue";
import { Component } from "vue-property-decorator";
import LoginCard from "@/components/LoginCard.vue";
import { AccountType, AuthResponse, LoginRequest } from "@/store/auth/types";
import { namespace } from "vuex-class";
import { isTwoFAActive } from '@/api/base'

const authModule = namespace("auth");

@Component({
  name: "LoginView",
  components: { LoginCard, HelloWorld },
})
export default class LoginView extends Vue {
  @authModule.Action("login") login!: any;

  onLoginClicked(newVal: LoginRequest) {
    this.login(newVal)
      .then((val: AuthResponse) => {
        if (val.is_2fa_ready || !isTwoFAActive()) {
          if (val.is_2fa || !isTwoFAActive()) {
            if (val.user.account_type === AccountType.VEREIN) {
              this.$router.push("/news");
            } else {
              this.$router.push("/vereine");
            }
          } else {
            this.$router.push("/two_fa_login");
          }
        } else {
          this.$router.push("/two_fa_setup");
        }
      })
      .catch((val: any) => {
        console.log(val);
      });
  }
}
