import { AccountType } from "@/store/auth/types";

export enum AgeGroup {
  UNTIL_20,
  TWENTY_ONE_TO_FORTY,
  OVER_40,
}
export enum UserStatus {
  ACTIVE = "active",
  PENDING = "pending",
  BANNED = "banned",
}

export function userStatusToText(status: UserStatus): string {
  switch (status) {
    case UserStatus.ACTIVE:
      return "Aktiv";
    case UserStatus.PENDING:
      return "Ausstehend";
    case UserStatus.BANNED:
      return "Gesperrt";
  }
}

export interface UserResponse {
  uuid: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  age_group: AgeGroup | null;
  avatar_url: string | null;
  account_type: AccountType;
  status: UserStatus;
}

export interface BanUserRequest {
  uuid: string;
}

export interface DeleteUserRequest {
  uuid: string;
}

export interface AdminUsersResponse {
  users: Array<UserResponse>;
}

export interface UsersState {
  users: Array<UserResponse>;
}
