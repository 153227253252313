import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/store";
import { ROUTES } from "@/constants/routes";
import { textForErrorCode, unknownError } from "@/store/error/types";
import { wrapper } from 'axios-cookiejar-support';
import {CookieJar} from "tough-cookie";

export const baseURL =
  process.env.VUE_APP_API_URL || "https://ksb-sport-dev.mobivention.eu/";

export function isTwoFAActive(): boolean {
  let isActive = process.env.VUE_APP_2FA_ACTIVE;
  if (isActive === undefined || isActive === null) {
    isActive = true;
  }
  return isActive !== "false";
}

const apiAxios = axios.create({
  baseURL,
});

apiAxios.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    // start loading
    store.commit("loading/startLoading", request.url);
    const token = store.state.auth.token;
    if (!request.headers) {
      request.headers = {};
    }
    if (request.headers) {
      if (request.method !== "OPTIONS") {
        request.headers.Accept = "application/json";
      }
      if (token && token !== "") {
        if (request.url !== ROUTES.LOGIN) {
          request.headers.Authorization = `Bearer ${token}`;
        }
      }
    }
    return request;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

apiAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    // stop loading
    store.commit(
      "loading/stopLoading",
      response.request.responseURL.replace(baseURL, "")
    );
    if (response.status >= 500) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject();
    } else {
      return response;
    }
    if(response.headers["set-cookie"]) {
      store.commit(
        "vereine/setVereinSession",
        response.headers["set-cookie"]
      )
    }
  },
  (error: any) => {
    // stop loading
    store.commit(
      "loading/stopLoading",
      error.request.responseURL.replace(baseURL, "")
    );
    if (
      error.response &&
      error.response.data &&
      error.response.data.short_code
    ) {
      store.commit("error/showError", error.response.data);
      return Promise.reject(error.response.data);
    } else {
      store.commit("error/showError", unknownError());
      return Promise.reject(textForErrorCode(null));
    }
  }
);

export default apiAxios;
