import { GetterTree, Module, MutationTree } from "vuex";
import { LoadingState } from "./types";
import { RootState } from "../types";

const getDefaultState = () => {
  return {
    currentlyLoading: [],
  };
};

const state = getDefaultState();

// mutations
const mutations: MutationTree<LoadingState> = {
  startLoading(state, actionId: string) {
    state.currentlyLoading.push(actionId);
  },
  stopLoading(state, actionId: string) {
    let cleanActionId = actionId;
    if (cleanActionId.startsWith("/")) {
      cleanActionId = cleanActionId.replace("/", "");
    }
    cleanActionId = cleanActionId.split("?")[0];
    // If starts with auth remove all auth routes
    if (cleanActionId.startsWith("auth")) {
      const current = state.currentlyLoading;
      state.currentlyLoading = current.filter(
        (val: string) => !val.startsWith("auth") && !val.startsWith("/auth")
      );
    } else {
      const index = state.currentlyLoading.indexOf(cleanActionId);
      if (index !== -1) {
        state.currentlyLoading.splice(index, 1);
      }
    }
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

// getters
export const getters: GetterTree<LoadingState, RootState> = {
  isLoading:
    (state) =>
    (actionId: string): boolean => {
      return state.currentlyLoading.indexOf(actionId) >= 0;
    },
};

export const loading: Module<LoadingState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
};
