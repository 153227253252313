
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { UserResponse } from "@/store/users/types";
import UserList from "@/components/UserList.vue";
import { ROUTES } from '@/constants/routes'
import LoadingIndicator from '@/components/LoadingIndicator.vue'

const usersModule = namespace("users");
const loadingModule = namespace("loading");

@Component({
  name: "UserListView",
  components: { LoadingIndicator, UserList },
})
export default class UserListView extends Vue {
  @usersModule.Action("fetchUsers") fetchUsers!: any;
  @usersModule.State("users") users!: Array<UserResponse>;
  @loadingModule.Getter("isLoading") isLoading: any;

  routes = ROUTES;

  mounted() {
    this.fetchUsers();
  }
}
