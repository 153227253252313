
import { Component, Vue } from "vue-property-decorator";
import { LoginRequest } from "@/store/auth/types";
import { namespace } from "vuex-class";
import { ROUTES } from "@/constants/routes";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

const loadingModule = namespace("loading");

@Component({
  name: "LoginCard",
  components: { LoadingIndicator },
})
export default class LoginCard extends Vue {
  @loadingModule.Getter("isLoading") isLoading: any;

  routes = ROUTES;

  email = "";
  password = "";

  onLoginClicked() {
    const value: LoginRequest = {
      email: this.email,
      password: this.password,
    };
    this.$emit("on-login-clicked", value);
  }
}
