import { render, staticRenderFns } from "./EditNewsView.vue?vue&type=template&id=e005460a&scoped=true&"
import script from "./EditNewsView.vue?vue&type=script&lang=ts&"
export * from "./EditNewsView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e005460a",
  null
  
)

export default component.exports