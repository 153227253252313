
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import TwoFactorSetup from "@/components/TwoFactorSetup.vue";
import {
  AccountType,
  AuthResponse,
  TwoFASetupDoneRequest,
} from "@/store/auth/types";

const authModule = namespace("auth");

@Component({
  name: "TwoFactorSetupView",
  components: { TwoFactorSetup },
})
export default class TwoFactorSetupView extends Vue {
  @authModule.Action("confirmTwoFaSetup") confirmTwoFa!: any;

  confirmCode(confirmedCode: string) {
    const request: TwoFASetupDoneRequest = {
      code: confirmedCode,
    };
    this.confirmTwoFa(request).then((val: AuthResponse) => {
      if (val.user.account_type === AccountType.VEREIN) {
        this.$router.replace("/news");
      } else {
        this.$router.replace("/vereine");
      }
    });
  }
}
