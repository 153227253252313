
import {Component} from "vue-property-decorator";
import Vue, {ref} from "vue";
import {namespace} from "vuex-class";
import {
  DownloadExcelResponse,
  UploadDefVereinsExcelRequest, UploadFacilitiesRequest
} from "@/store/vereine/types";
import {vereine} from "@/store/vereine";

const vereineModule = namespace("vereine");
@Component({
  name: "ExpImpFacilitiesView",
})

export default class ExpImpFacilitiesView extends Vue {
  @vereineModule.Action("downloadFacilitiesExcel") downloadFacilitiesExcel!: any;
  @vereineModule.Action("uploadFacilitiesExcel") uploadFacilitiesExcel!: any;
  @vereineModule.Action("overwriteWithFacilitiesExcel") overwriteWithFacilitiesExcel!: any;

  protected import_file: any
  protected server_file: string | undefined
  success = ref<boolean>(false)
  showOverwriteButton = ref<boolean>(false)

  submitFacilitiesForm() {
    const formData = new FormData
    formData.append('import_file', this.import_file)
    const data: UploadFacilitiesRequest = {
      dry_run: true,
      import_file: formData
    }
    this.uploadFacilitiesExcel(data)
      .then((val: any) => {
        if(val.data.import_success === true) {
          this.showOverwriteButton = true
          this.server_file = val.data.tmp_file
        }
      })
  }

  overwriteWithUploadedExcel() {
    const formData = new FormData
    formData.append('import_file', this.import_file)
    const data: UploadFacilitiesRequest = {
      dry_run: false,
      import_file: formData,
      tmp_file: this.server_file
    }
    this.overwriteWithFacilitiesExcel(data)
      .then((res: any) => {
        this.showOverwriteButton = false
        this.success = true
      })
  }

  onDownloadFacilitiesExcel() {
    this.downloadFacilitiesExcel()
      .then((val: any) => {
        console.log(val)
      })
  }
}
