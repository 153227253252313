
import {Component} from "vue-property-decorator";
import Vue, {ref} from "vue";
import {namespace} from "vuex-class";
import {DownloadExcelResponse, UploadCurVereinsExcelRequest} from "@/store/vereine/types";
import {vereine} from "@/store/vereine";

const vereineModule = namespace("vereine");
@Component({
  name: "ExpImpProgramsView",
})

export default class ExpImpProgramsView extends Vue {
  @vereineModule.Action("downloadCurVereinsExcel") downloadCurVereinsExcel!: any;
  @vereineModule.Action("uploadCurVereinsExcel") uploadCurVereinsExcel!: any;
  @vereineModule.Action("overwriteWithCurVereinsExcel") overwriteWithCurVereinsExcel!: any;

  protected import_file: any
  protected server_file: string

  showOverwriteButton = ref<boolean>(false)

  submitForm() {
    const formData = new FormData
    formData.append('import_file', this.import_file)
    const data: UploadCurVereinsExcelRequest = {
      dry_run: true,
      import_file: formData
    }
    this.uploadCurVereinsExcel(data)
      .then((val: any) => {
        if(val.data.import_success === true) {
          this.showOverwriteButton = true
          this.server_file = val.data.tmp_file
        }
      })
  }

  overwriteWithUploadedExcel() {
    const formData = new FormData
    formData.append('import_file', this.import_file)
    const data: UploadCurVereinsExcelRequest = {
      dry_run: false,
      import_file: formData,
      tmp_file: this.server_file
    }
    this.overwriteWithCurVereinsExcel(data)
      .then((res: any) => {
        this.showOverwriteButton = false
      })
  }

  onDownloadVereinsExcel() {
    this.downloadCurVereinsExcel()
      .then((val: any) => {
        console.log(val)
      })
  }
}
