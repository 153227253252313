var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('h2',{domProps:{"textContent":_vm._s(this.newsToEdit ? 'News bearbeiten' : 'News erstellen')}}),_c('v-textarea',{staticClass:"mt-8",attrs:{"label":"Titel","hint":"Titel der News eingeben","solo":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('editor',{attrs:{"api-key":"no-api-key","init":{
      menubar: false,
      plugins: [
        'link',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
         bullist numlist outdent indent | removeformat | help',
    }},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),(this.newsToEdit && this.newsToEdit.image_url)?_c('div',{staticClass:"mt-4"},[_c('h3',{domProps:{"textContent":_vm._s('Aktuelles Bild')}}),_c('v-img',{staticClass:"edit-image",attrs:{"lazy-src":_vm.newsToEdit.image_url}})],1):_vm._e(),_c('v-file-input',{staticClass:"mt-4",attrs:{"solo":"","placeholder":"Titelbild für die News auswählen (Optional)","accept":"image/jpeg, image/png","label":"Bild (optional)"},model:{value:(_vm.imageFile),callback:function ($$v) {_vm.imageFile=$$v},expression:"imageFile"}}),(
      this.newsToEdit
        ? _vm.isLoading(_vm.routes.NEWS_EDIT(this.newsToEdit.id))
        : _vm.isLoading(_vm.routes.CREATE_NEWS)
    )?_c('LoadingIndicator'):_c('v-btn',{staticClass:"align-content-end mt-4",attrs:{"color":"primary","large":"","disabled":_vm.title.trim() === '' || _vm.text.trim() === ''},domProps:{"textContent":_vm._s(this.newsToEdit ? 'Bearbeiten' : 'Erstellen')},on:{"click":_vm.onButtonClicked}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }