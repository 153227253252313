import { UserResponse } from "@/store/users/types";

export enum AccountType {
  USER,
  VEREIN,
  ADMIN,
}

export function accountTypeToText(type: AccountType): string {
  switch (type) {
    case AccountType.USER:
      return "Nutzer";
    case AccountType.VEREIN:
      return "Verein";
    case AccountType.ADMIN:
      return "Admin";
  }
}

export interface AuthResponse {
  token: string;
  is_2fa: boolean;
  is_2fa_ready: boolean;
  user: UserResponse;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface TwoFASetupDoneRequest {
  code: string;
}

export interface TwoFALoginRequest {
  code: string;
}

export interface AuthState {
  token: string | null;
  isAdmin: boolean;
  isTwoFa: boolean;
  isTwoFaReady: boolean;
  user: UserResponse | null;
  twoFaCode: string | null;
  twoFaQrCode: string | null;
}
