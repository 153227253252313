
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { NewsResponse } from "@/store/news/types";
import { formatReadableDate } from "@/utility/DateUtil";
import { namespace } from "vuex-class";

const authModule = namespace("auth");

@Component({
  name: "NewsView",
  components: {},
})
export default class NewsView extends Vue {
  @Prop(Array) news!: Array<NewsResponse>;
  @Prop(Boolean) isLoading!: boolean;
  @authModule.State("isAdmin") isAdmin!: boolean;

  formattedDate(obj: NewsResponse): string {
    return formatReadableDate(obj.created_at);
  }

  showActionForItem(newsItem: NewsResponse): boolean {
    return (this.isAdmin || newsItem.verein !== null) && !newsItem.is_public;
  }

  onNewsDeleteClicked(newsItem: NewsResponse) {
    this.$emit("on-delete-clicked", newsItem);
  }

  onNewsEditClicked(newsItem: NewsResponse) {
    this.$emit("on-edit-clicked", newsItem);
  }
}
