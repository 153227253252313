import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import InfoView from "../views/InfoView.vue";
import SurveyView from "../views/SurveyView.vue";
import UserListView from "../views/UserListView.vue";
import ClubListView from "../views/ClubListView.vue";
import NewsView from "../views/NewsView.vue";
import CreateNewsView from "../views/CreateNewsView.vue";
import TwoFactorSetupView from "../views/TwoFactorSetupView.vue";
import TwoFactorLoginView from "../views/TwoFactorLoginView.vue";
import LoginView from "../views/LoginView.vue";
import EditNewsView from "@/views/EditNewsView.vue";
import ExpImpProgramsView from "@/views/ExpImpProgramsView.vue";
import ExpImpProgramsAdminView from "@/views/ExpImpProgramsAdminView.vue";
import ExpImpFacilitiesView from "@/views/ExpImpFacilitiesView.vue";
import ExpImpFacilitiesAdminView from "@/views/ExpImpFacilitiesAdminView.vue";
import BannerAdminView from "@/views/BannerAdminView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "LoginView",
    component: LoginView,
  },
  {
    path: "/vereine",
    name: "ClubListView",
    component: ClubListView,
  },
  {
    path: "/info",
    name: "InfoView",
    component: InfoView,
  },
  {
    path: "/survey",
    name: "SurveyView",
    component: SurveyView,
  },
  {
    path: "/user",
    name: "UserListView",
    component: UserListView,
  },
  {
    path: "/news",
    name: "NewsView",
    component: NewsView,
  },
  {
    path: "/create_news",
    name: "CreateNewsView",
    component: CreateNewsView,
  },
  {
    path: "/news/edit/:id",
    name: "EditNewsView",
    component: EditNewsView,
  },
  {
    path: "/two_fa_setup",
    name: "TwoFactorSetupView",
    component: TwoFactorSetupView,
  },
  {
    path: "/two_fa_login",
    name: "TwoFactorLoginView",
    component: TwoFactorLoginView,
  },
  {
    path: "/programs",
    name: "ExpImpProgramsView",
    component: ExpImpProgramsView,
  },
  {
    path: "/programs-admin",
    name: "ExpImpProgramsAdminView",
    component: ExpImpProgramsAdminView,
  },
  {
    path: "/programs-admin/:id",
    name: "ExpImpProgramsAdminView",
    component: ExpImpProgramsAdminView,
  },
  {
    path: "/facilities",
    name: "ExpImpFacilitiesView",
    component: ExpImpFacilitiesView,
  },
  {
    path: "/facilities-admin",
    name: "ExpImpFacilitiesAdminView",
    component: ExpImpFacilitiesAdminView,
  },
  {
    path: "/banner",
    name: "BannerAdminView",
    component: BannerAdminView,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
