
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ChangeVereinStatusRequest,
  DeleteVereinRequest,
  textForStatus,
  VereinAcceptanceStatus,
  VereinResponse,
} from "@/store/vereine/types";
import { namespace } from "vuex-class";
import { DataTableHeader } from "vuetify";

const vereineModule = namespace("vereine");

@Component({
  name: "ClubList",
  components: {},
})
export default class ClubList extends Vue {
  @Prop(Array) readonly vereine!: Array<VereinResponse>;
  @Prop(Number) readonly showWorkedOn!: boolean;
  @vereineModule.Action("changeVereinStatus") changeVereinStatus!: any;
  @vereineModule.Action("deleteVerein") deleteVerein!: any;

  textForVereinStatus(verein: VereinResponse): string {
    return textForStatus(verein.status);
  }

  acceptVerein(verein: VereinResponse) {
    const request: ChangeVereinStatusRequest = {
      id: verein.id,
      status: VereinAcceptanceStatus.ACCEPTED,
    };
    this.changeVereinStatus(request);
  }

  declineVerein(verein: VereinResponse) {
    const request: ChangeVereinStatusRequest = {
      id: verein.id,
      status: VereinAcceptanceStatus.DECLINED,
    };
    this.changeVereinStatus(request);
  }

  deleteThisVerein(verein: VereinResponse) {
    const request: DeleteVereinRequest = {
      id: verein.id,
    };
    this.deleteVerein(request);
  }

  checkPrograms(verein: VereinResponse) {
    this.$router.push("/programs-admin/" + verein.id)
  }

  get headers(): DataTableHeader[] {
    const headers: DataTableHeader[] = [];
    if (this.showWorkedOn) {
      headers.push({
        text: "",
        align: "start",
        sortable: false,
        value: "avatar_url",
      });
    }
    headers.push({
      text: "Vereins-Nr.",
      value: "vereins_nr",
      sortable: true,
    });
    if (this.showWorkedOn) {
      headers.push({
        text: "Name",
        value: "name",
        sortable: true,
      });
    }
    headers.push({
      text: "Status",
      value: "status",
      sortable: true,
    });
    headers.push({
      text: "Aktionen",
      value: "",
      sortable: false,
    });
    return headers;
  }
}
