import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import api from "@/api/base";
import { AxiosResponse } from "axios";
import { ROUTES } from "@/constants/routes";
import {
  AdminUsersResponse,
  BanUserRequest,
  DeleteUserRequest,
  UsersState,
  UserStatus,
} from "./types";
import { RootState } from "../types";

const getDefaultState = () => {
  return {
    users: [],
  };
};

const state = getDefaultState();

const actions: ActionTree<UsersState, RootState> = {
  fetchUsers({ commit }) {
    api
      .get(ROUTES.USERS)
      .then((response: AxiosResponse) => commit("setUsers", response.data))
      .catch((reason) => console.error(reason));
  },
  banUser({ commit }, data: BanUserRequest) {
    api
      .post(ROUTES.BAN_USER(data.uuid))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response: AxiosResponse) => commit("bannedUser", data.uuid))
      .catch((reason) => console.error(reason));
  },
  deleteUser({ commit }, data: DeleteUserRequest) {
    api
      .delete(ROUTES.DELETE_USER(data.uuid))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response: AxiosResponse) => commit("deletedUser", data.uuid))
      .catch((reason) => console.error(reason));
  },
};

// mutations
const mutations: MutationTree<UsersState> = {
  setUsers(state, response: AdminUsersResponse) {
    state.users = response.users;
  },
  bannedUser(state, uuid: string) {
    const current = state.users;
    current.forEach((val) => {
      if (val.uuid === uuid) {
        val.status = UserStatus.BANNED;
      }
    });
    state.users = current;
  },
  deletedUser(state, uuid: string) {
    let current = state.users;
    current = current.filter((val) => {
      return val.uuid !== uuid;
    });
    state.users = current;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<UsersState, RootState> = {};

export const users: Module<UsersState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
