
import Vue from "vue";
import { Component } from "vue-property-decorator";
import CreateOrEditNews from "@/components/CreateOrEditNews.vue";

@Component({
  name: "CreateNewsView",
  components: { CreateOrEditNews },
})
export default class CreateNewsView extends Vue {}
