
import Vue from "vue";
import { Component } from "vue-property-decorator";
import SideNav from "@/components/SideNav.vue";
import { namespace } from "vuex-class";
import { AccountType, AuthResponse } from "@/store/auth/types";
import { isTwoFAActive } from '@/api/base'
import { ApiError, textForErrorCode } from "@/store/error/types";

const authModule = namespace("auth");
const errorModule = namespace("error");

@Component({
  name: "App",
  components: { SideNav },
})
export default class App extends Vue {
  @authModule.State("token") authToken: string | null | undefined;
  @authModule.Getter("isLoggedIn") isLoggedIn: any;
  @authModule.Action("auth") auth: any;
  @errorModule.State("currentError") error!: ApiError | null;
  @errorModule.Getter("showError") showError: any;
  @errorModule.Mutation("resetState") hideError: any;

  mounted() {
    console.log("App started");
    if (this.isLoggedIn()) {
      this.auth().then((val: AuthResponse) => {
        if (val.is_2fa_ready || !isTwoFAActive()) {
          if (val.is_2fa || !isTwoFAActive()) {
            if (val.user.account_type === AccountType.VEREIN) {
              this.$router.push("/news");
            } else {
              this.$router.push("/vereine");
            }
          } else {
            this.$router.push("/two_fa_login");
          }
        } else {
          this.$router.push("/two_fa_setup");
        }
      });
    }
  }

  get getErrorText(): string {
    return textForErrorCode(this.error?.short_code);
  }

  dismissDialog() {
    this.hideError();
  }
}
