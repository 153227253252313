
import {Component} from "vue-property-decorator";
import Vue, {ref} from "vue";
import {namespace} from "vuex-class";
import {
  DownloadExcelResponse,
  UploadDefVereinsExcelRequest
} from "@/store/vereine/types";
import {vereine} from "@/store/vereine";

const vereineModule = namespace("vereine");
@Component({
  name: "ExpImpProgramsAdminView",
})

export default class ExpImpProgramsAdminView extends Vue {
  @vereineModule.Action("downloadDefinedVereinsExcel") downloadDefinedVereinsExcel!: any;
  @vereineModule.Action("uploadDefinedVereinsExcel") uploadDefinedVereinsExcel!: any;
  @vereineModule.Action("overwriteWithDefinedVereinsExcel") overwriteWithDefinedVereinsExcel!: any;

  protected import_file: any
  protected server_file: string | undefined
  protected verein_id: any
  success = ref<boolean>(false)
  showOverwriteButton = ref<boolean>(false)

  created() {
    this.verein_id = this.$route.params.id
  }

  onVereinIdChange () {
    console.log(this.verein_id)
  }

  submitDefForm() {
    if(this.verein_id === undefined) {
      this.$store.commit("error/showError", 'no_verein_id')
    }
    const formData = new FormData
    formData.append('import_file', this.import_file)
    const data: UploadDefVereinsExcelRequest = {
      dry_run: true,
      import_file: formData,
      verein_id: this.verein_id
    }
    this.uploadDefinedVereinsExcel(data)
      .then((val: any) => {
        if(val.data.import_success === true) {
          this.showOverwriteButton = true
          this.server_file = val.data.tmp_file
        }
      })
  }

  overwriteDefWithUploadedExcel() {
    if(this.verein_id === undefined) {
      this.$store.commit("error/showError", 'no_verein_id')
    }
    const formData = new FormData
    formData.append('import_file', this.import_file)
    const data: UploadDefVereinsExcelRequest = {
      dry_run: false,
      import_file: formData,
      tmp_file: this.server_file,
      verein_id: this.verein_id
    }
    this.overwriteWithDefinedVereinsExcel(data)
      .then((res: any) => {
        this.showOverwriteButton = false
        this.success = true
      })
  }

  onDownloadDefVereinsExcel() {
    console.log(this.verein_id)
    if(this.verein_id === undefined) {
      this.$store.commit("error/showError", 'no_verein_id')
    }
    this.downloadDefinedVereinsExcel(this.verein_id)
      .then((val: any) => {
        console.log(val)
      })
  }
}
