
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { UserResponse } from "@/store/users/types";

const authModule = namespace("auth");

export interface NavItem {
  title: string;
  routeName: string;
  class: string;
}

@Component({
  name: "SideNav",
})
export default class SideNav extends Vue {
  @authModule.Getter("isLoggedIn") isLoggedIn!: any;
  @authModule.Action("logout") logout!: any;
  @authModule.State("user") user!: UserResponse;
  @authModule.State("isAdmin") isAdmin!: boolean;

  get navItems(): NavItem[] {
    const items: NavItem[] = [];
    if (this.isAdmin) {
      items.push({
        title: "Vereine",
        routeName: "ClubListView",
        class: "navbar-item",
      });
      items.push({
        title: "Nutzer",
        routeName: "UserListView",
        class: "navbar-item",
      });
    }
    items.push({ title: "News", routeName: "NewsView", class: "navbar-item" });
    if (this.isAdmin) {
      items.push({ title: "Sportangebote Admin", routeName: "ExpImpProgramsAdminView", class: "navbar-item" });
      items.push({ title: "Sportstätten Admin", routeName: "ExpImpFacilitiesAdminView", class: "navbar-item" });
      items.push({ title: "Werbebanner", routeName: "BannerAdminView", class: "navbar-item" });
    } else {
      items.push({ title: "Sportangebote", routeName: "ExpImpProgramsView", class: "navbar-item" });
      items.push({ title: "Sportstätten", routeName: "ExpImpFacilitiesView", class: "navbar-item" });
    }
    return items;
  }

  onLogoutClicked() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.logout().finally((val: any) => {
      this.$router.replace("/");
    });
  }
}
