
import {Component, Watch} from "vue-property-decorator";
import Vue, {ref} from "vue";
import {namespace} from "vuex-class";
import {
  DownloadExcelResponse,
  UploadDefVereinsExcelRequest, UploadFacilitiesRequest
} from "@/store/vereine/types";
import {vereine} from "@/store/vereine";
import {blobToBase64} from "@/utility/FileUtil";
import {CreateBannerRequest, PatchBannerRequest} from "@/store/news/types";

const newsModule = namespace("news");
@Component({
  name: "BannerAdminView",
})

export default class BannerAdminView extends Vue {
  @newsModule.Action("createBanner") createBanner!: any;
  @newsModule.Action("fetchBanner") fetchBanner!: any;
  @newsModule.Action("editBanner") editBanner!: any;

  protected banner_img = ref("")
  protected banner_link = ""
  protected active = false
  success = ref<boolean>(false)
  imageFile = null;
  imageBase64: string | null = null;
  hasImageChanged = false;

  created() {
    this.fetchBanner()
      .then((val: any) => {
        this.banner_img = val.image_url
        this.banner_link = val.link_url
        this.active = val.is_active
      })
  }

  onChangeBanner() {
    if(this.hasImageChanged) {
      const data: CreateBannerRequest = {
        is_active: this.active,
        link_url: this.banner_link,
        base64_image: this.imageBase64
      }
      console.log(data)
      this.createBanner(data)
        .then(this.success = true)
    } else {
      const data: PatchBannerRequest = {
        is_active: this.active,
        link_url: this.banner_link
      }
      this.editBanner(data)
        .then(this.success = true)
    }
  }

  @Watch("imageFile")
  onFileAttached(newVal: File | undefined) {
    if (newVal) {
      blobToBase64(newVal).then((base64: any) => {
        this.imageBase64 = base64;
      });
      this.hasImageChanged = true;
    } else {
      this.imageBase64 = null;
    }
  }
}
