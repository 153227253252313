export interface ApiError {
  short_code: string;
  description: string;
}

export function unknownError(): ApiError {
  return {
    short_code: "",
    description: "",
  };
}

export function textForErrorCode(code: string | null | undefined) {
  switch (code) {
    case "invalid_credentials":
      return "Ihre eingebenen Logindaten sind nicht korrekt";
    case "session_expired":
      return "Ihre Sitzung ist abgelaufen, bitte loggen Sie sich erneut ein.";
    case "verein_not_found":
      return "Dieser Verein konnte nicht gefunden werden. Eventuell wurde dieser gelöscht.";
    case "not_found":
      return "Die angefragten Daten konnten nicht gefunden werden. Eventuell wurde diese gelöscht.";
    case "user_banned":
      return "Ihr Account wurde gesperrt. Sie können sich nicht mehr einloggen.";
    case "not_authorized":
      return "Ihnen ist nicht erlaubt diese Funktionalität zu nutzen.";
    case "verein_not_accepted_yet":
      return "Die Registrierung ihres Vereins wurde noch nicht akzeptiert. Sie können sich erst Einloggen wenn dies passiert ist.";
    case "no_verein_id":
      return "Bitte geben Sie zuerst eine Verein-Nummer ein."
    default:
      return "Unbekannter Fehler";
  }
}

export interface ErrorState {
  currentError: ApiError | null;
}
