
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  name: "LoadingIndicator",
  components: {},
})
export default class LoadingIndicator extends Vue {
  @Prop({ type: Boolean, default: false }) isCenter!: boolean;
}
