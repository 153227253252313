import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import api from "@/api/base";
import { AxiosResponse } from "axios";
import { ROUTES } from "@/constants/routes";
import {
  AdminVereineResponse,
  ChangeVereinStatusRequest,
  DeleteVereinRequest,
  UploadCurVereinsExcelRequest,
  UploadDefVereinsExcelRequest,
  UploadFacilitiesRequest,
  VereinAcceptanceStatus,
  VereineState,
  VereinResponse,
} from "./types";
import { RootState } from "../types";

const getDefaultState = () => {
  return {
    vereine: [],
    vereinsession: ''
  };
};

const state = getDefaultState();

const actions: ActionTree<VereineState, RootState> = {
  fetchVereine({ commit }) {
    api
      .get(ROUTES.VEREINE)
      .then((response: AxiosResponse) => commit("setVereine", response.data))
      .catch((reason) => console.error(reason));
  },
  changeVereinStatus({ commit }, data: ChangeVereinStatusRequest) {
    api
      .post(ROUTES.VEREIN_STATUS(data.id), data)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response: AxiosResponse) => commit("changeVereinStatus", data))
      .catch((reason) => console.error(reason));
  },
  deleteVerein({ commit }, data: DeleteVereinRequest) {
    api
      .delete(ROUTES.VEREIN_DELETE(data.id))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response: AxiosResponse) => commit("deletedVerein", data))
      .catch((reason) => console.error(reason));
  },
  downloadCurVereinsExcel({ commit }) {
    api
      .post(ROUTES.VEREIN_CUR_DOWNLOAD, {file_name: 'Sportangebote.xlsx'}, {responseType: 'blob'})
      .then((response: AxiosResponse) => {
        const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Sportangebote.xlsx')
        document.body.appendChild(link)
        link.click()
        console.log(response)
      })
      .catch((reason) => {
        console.error(reason);
      });
  },
  uploadCurVereinsExcel({ commit }, data: UploadCurVereinsExcelRequest) {
    return new Promise((resolve, reject) => {
      api
        .post(
          ROUTES.VEREIN_CUR_UPLOAD,
          data.import_file,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            params: {
              dry_run: 1
            }
          }
        )
        .then((response: AxiosResponse) => {
          resolve(response)
        })
        .catch((reason: AxiosResponse) => {
          reject(reason)
        })
    })
  },
  overwriteWithCurVereinsExcel({commit}, data) {
    return new Promise((resolve, reject) => {
      api
        .post(
          ROUTES.VEREIN_CUR_UPLOAD,
          {},
          {
            params: {
              dry_run: 0,
              tmp_file: data.tmp_file
            }
          }
        )
        .then((response: AxiosResponse) => {
          resolve(response)
        })
        .catch((reason: AxiosResponse) => {
          reject(reason)
        })
    })
  },
  downloadDefinedVereinsExcel({ commit }, verein_id) {
    console.log(ROUTES.VEREIN_ID_DOWNLOAD(verein_id))
    api
      .post(ROUTES.VEREIN_ID_DOWNLOAD(verein_id), {}, {responseType: 'blob'})
      .then((response: AxiosResponse) => {
        const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Sportangebote.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      .catch((reason) => {
        console.error(reason);
      });
  },
  uploadDefinedVereinsExcel({ commit }, data: UploadDefVereinsExcelRequest) {
    return new Promise((resolve, reject) => {
      api
        .post(
          ROUTES.VEREIN_ID_UPLOAD(data.verein_id),
          data.import_file,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            params: {
              dry_run: 1
            }
          }
        )
        .then((response: AxiosResponse) => {
          resolve(response)
        })
        .catch((reason: AxiosResponse) => {
          reject(reason)
        })
    })
  },
  overwriteWithDefinedVereinsExcel({commit}, data: UploadDefVereinsExcelRequest) {
    return new Promise((resolve, reject) => {
      api
        .post(
          ROUTES.VEREIN_ID_UPLOAD(data.verein_id),
          {},
          {
            params: {
              dry_run: 0,
              tmp_file: data.tmp_file
            }
          }
        )
        .then((response: AxiosResponse) => {
          resolve(response)
        })
        .catch((reason: AxiosResponse) => {
          reject(reason)
        })
    })
  },
  downloadFacilitiesExcel({ commit }) {
    api
      .post(ROUTES.FACILITIES_DL, {file_name: 'Sportstaetten.xlsx'}, {responseType: 'blob'})
      .then((response: AxiosResponse) => {
        const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Sportstaetten.xlsx')
        document.body.appendChild(link)
        link.click()
        console.log(response)
      })
      .catch((reason) => {
        console.error(reason);
      });
  },
  uploadFacilitiesExcel({ commit }, data: UploadFacilitiesRequest) {
    return new Promise((resolve, reject) => {
      api
        .post(
          ROUTES.FACILITIES_UL,
          data.import_file,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            params: {
              dry_run: 1
            }
          }
        )
        .then((response: AxiosResponse) => {
          resolve(response)
        })
        .catch((reason: AxiosResponse) => {
          reject(reason)
        })
    })
  },
  overwriteWithFacilitiesExcel({commit}, data) {
    return new Promise((resolve, reject) => {
      api
        .post(
          ROUTES.FACILITIES_UL,
          {},
          {
            params: {
              dry_run: 0,
              tmp_file: data.tmp_file
            }
          }
        )
        .then((response: AxiosResponse) => {
          resolve(response)
        })
        .catch((reason: AxiosResponse) => {
          reject(reason)
        })
    })
  },
};

// mutations
const mutations: MutationTree<VereineState> = {
  setVereine(state, response: AdminVereineResponse) {
    state.vereine = response.vereine;
  },
  changeVereinStatus(state, data: ChangeVereinStatusRequest) {
    const current = state.vereine;
    current.forEach((val) => {
      if (val.id === data.id) {
        val.status = data.status;
      }
    });
    state.vereine = current;
  },
  deletedVerein(state, id: number) {
    let current = state.vereine;
    current = current.filter((val) => {
      return val.id !== id;
    });
    state.vereine = current;
  },
  setVereinSession(state, cookie: string){
    state.vereinsession = cookie
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters: GetterTree<VereineState, RootState> = {
  onlyOpenVereine: (state) => (): Array<VereinResponse> => {
    return state.vereine.filter((val: VereinResponse) => {
      return val.status === VereinAcceptanceStatus.OPEN;
    });
  },
  onlyWorkedOnVereine: (state) => (): Array<VereinResponse> => {
    return state.vereine.filter((val: VereinResponse) => {
      return val.status !== VereinAcceptanceStatus.OPEN;
    });
  },
};

export const vereine: Module<VereineState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
