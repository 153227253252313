import { GetterTree, Module, MutationTree } from "vuex";
import { ApiError, ErrorState } from "./types";
import { RootState } from "../types";

const getDefaultState = () => {
  return {
    currentError: null,
  };
};

const state = getDefaultState();

// mutations
const mutations: MutationTree<ErrorState> = {
  showError(state, error: ApiError) {
    state.currentError = error;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

// getters
export const getters: GetterTree<ErrorState, RootState> = {
  showError: (state) => (): boolean => {
    return state.currentError !== null;
  },
};

export const error: Module<ErrorState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
};
