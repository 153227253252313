
import Vue from "vue";
import { Component } from "vue-property-decorator";
import CreateOrEditNews from "@/components/CreateOrEditNews.vue";
import { NewsResponse } from "@/store/news/types";
import { namespace } from "vuex-class";

const newsModule = namespace("news");

@Component({
  name: "EditNewsView",
  components: { CreateOrEditNews },
})
export default class EditNewsView extends Vue {
  @newsModule.Getter("getNewsById") getNewsById: any;
  newsToEdit: NewsResponse | undefined = undefined;

  created() {
    this.newsToEdit = this.getNewsById(Number(this.$route.params.id));
    console.log(this.newsToEdit);
  }
}
