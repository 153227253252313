
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { NewsDeletionRequest, NewsResponse } from "@/store/news/types";
import NewsList from "@/components/NewsList.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { ROUTES } from "@/constants/routes";

const newsModule = namespace("news");
const loadingModule = namespace("loading");

@Component({
  name: "NewsView",
  components: { LoadingIndicator, NewsList },
})
export default class NewsView extends Vue {
  @newsModule.Action("fetchNewsFeed") fetchNewsFeed!: any;
  @newsModule.Action("deleteNews") deleteNews!: any;
  @newsModule.State("news") news!: Array<NewsResponse>;
  @loadingModule.Getter("isLoading") isLoading: any;

  routes = ROUTES;

  mounted() {
    this.fetchNewsFeed();
  }

  onCreateNewsClicked() {
    this.$router.push("/create_news");
  }

  onNewsDeleteClicked(newsItem: NewsResponse) {
    const request: NewsDeletionRequest = {
      id: newsItem.id,
    };
    this.deleteNews(request);
  }

  onNewsEditClicked(newsItem: NewsResponse) {
    this.$router.push({ name: "EditNewsView", params: { id: newsItem.id.toString() }});
  }
}
