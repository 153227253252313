import { render, staticRenderFns } from "./BannerAdminView.vue?vue&type=template&id=6df3a7ed&scoped=true&"
import script from "./BannerAdminView.vue?vue&type=script&lang=ts&"
export * from "./BannerAdminView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6df3a7ed",
  null
  
)

export default component.exports