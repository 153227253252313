export enum VereinAcceptanceStatus {
  OPEN = "open",
  ACCEPTED = "accepted",
  DECLINED = "declined",
}

export function textForStatus(status: VereinAcceptanceStatus): string {
  switch (status) {
    case VereinAcceptanceStatus.OPEN:
      return "Offen";
    case VereinAcceptanceStatus.ACCEPTED:
      return "Freigeschaltet";
    case VereinAcceptanceStatus.DECLINED:
      return "Abgelehnt";
  }
}

export interface VereinResponse {
  id: number;
  vereins_nr: string;
  name: string;
  status: VereinAcceptanceStatus;
}

export interface ChangeVereinStatusRequest {
  id: number;
  status: VereinAcceptanceStatus;
}

export interface DeleteVereinRequest {
  id: number;
}

export interface AdminVereineResponse {
  vereine: Array<VereinResponse>;
}

export interface VereineState {
  vereine: Array<VereinResponse>;
  vereinsession: string
}

export interface UploadExcelRequest {
  dry_run: number;
}

export interface UploadCurVereinsExcelRequest {
  dry_run: boolean;
  import_file: FormData;
  tmp_file?: string
}

export interface UploadDefVereinsExcelRequest {
  dry_run: boolean;
  import_file: FormData;
  tmp_file?: string
  verein_id: number
}

export interface DownloadExcelResponse {
  detail: Array<any>
}

export interface UploadFacilitiesRequest {
  dry_run: boolean;
  import_file?: FormData;
  tmp_file?: string
}
