
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ROUTES } from "@/constants/routes";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

const loadingModule = namespace("loading");

@Component({
  name: "TwoFactorCode",
  components: { LoadingIndicator },
})
export default class TwoFactorCode extends Vue {
  @loadingModule.Getter("isLoading") isLoading: any;

  routes = ROUTES;
  enteredCode = "";

  onCodeEnteredClicked() {
    this.$emit("confirm-code-clicked", this.enteredCode);
  }
}
